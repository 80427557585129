<template>
    <div class="affiche-details" v-if="afficheDetails">
        <div class="title">{{ afficheDetails.ywTitle ? afficheDetails.ywTitle : '' }}</div>
        <div class="time"> {{ timeTitle }} :{{ afficheDetails.ywStartTime }} - {{ afficheDetails.ywEndTime }}</div>
        <div class="content" v-html="afficheDetails.ywCentont">
        </div>

        <div class="filebox">
            <div>文件附件：</div>
            <div class="file" v-for="(item, index) in afficheDetails.fileList" :key="index" @click="downloadd(item)">
                <span>{{ item.fjName }}</span>
            </div>
        </div>

        <div class="remark">
            <div>注：需要报名请通过电脑网页端进行报名</div>
            <div>报名地址：http://hj-blks.com</div>
        </div>

        <div class="phone-btn" @click="callPhone">电话咨询</div>
        <div class="technology">该系统由东莞市海基智远人力资源服务有限公司提供技术支持</div>


    </div>
</template>

<script>
import common from "@/api/common.js"
export default {
    data() {
        return {
            afficheDetails: null,
            id: null,
            timeTitle: "",
        }
    },

    mounted() {
        console.log(2122);
        this.id = this.$route.query.id;
        setTimeout(() => {
            this.getAfficheDetails()
        }, 500)
        this.$forceUpdate()
    },


    methods: {
        // 拨号
        callPhone() {
            window.location.href = 'tel://' + '0769-28631941'
        },

        // 下载文件
        downloadd(val) {
            var a = document.createElement('a') // 创建一个<a></a>标签
            a.href = val.fjAddress         //重点（如测试发现下载文件不存在/找不到，检查路径）
            a.download = val.fjName         // 设置下载文件文件名
            a.style.display = 'none'           // 隐藏a标签
            document.body.appendChild(a)       // 将a标签追加到文档对象中
            a.click()                          // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
            a.remove()
        },
        // 获取公告详情
        getAfficheDetails() {
            common.getTweeDetails(this.id).then(res => {
                if (res.code == 200) {
                    this.afficheDetails = res.data;
                    if (this.afficheDetails.fileList.length > 0) {
                        this.afficheDetails.fileList.forEach(item => {
                            item.fjAddress = this.$api + '/prod-api' + item.fjAddress;
                        })
                    }

                    if (this.afficheDetails.ywType == "招聘公告") {
                        this.timeTitle = "招聘时间"
                    } else if (this.afficheDetails.ywType == "准考证公告") {
                        this.timeTitle = "准考证打印时间"
                    } else if (this.afficheDetails.ywType == "成绩查询公告") {
                        this.timeTitle = "成绩查询时间"
                    } else if (this.afficheDetails.ywType == "延迟考试公告") {
                        this.timeTitle = "延迟考试时间"
                    }
                    console.log(this.afficheDetails, "公告详情");
                }
            })
        }
    },
}
</script>

<style scoped>
.affiche-details {
    min-height: 100vh;
    padding: 16px;
    box-sizing: border-box;
}

.title {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    margin-bottom: 12px;
}

.time {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 26px;
    color: #333333;
}

.content {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 26px;
    color: #333333;
    margin-top: 14px;
}

.filebox {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
}

.remark {
    font-size: 14px;
    font-weight: bold;
    font-family: PingFang SC;
    line-height: 26px;
    color: #333333;
    margin-top: 85px;
}

.remark>div {
    text-align: center;
}

.phone-btn {
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    background: #E01811;
    border-radius: 2px;
    margin-top: 8px;
}

.technology {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 50px;
    color: #999999;
    text-align: center;
}

.file {
    font-size: 14px;
    color: #4285f4;
    margin-top: 8px;
}
</style>